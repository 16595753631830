import { memo, useCallback, useEffect, useState, useContext } from 'react'
import { UserContext } from '../../Context'
import { Box } from '@mui/material'
import AccountPanel from './AccountPanel'
import Balances from './Balances'
import { getEntityInfo, getAccounts, getBalances } from '../../services/Balances'
import styles from './index.module.scss'
import { Decimal } from '../../utils'

const AccountBalances = () => {
    const { onLogin } = useContext(UserContext)
    const [activeAccountId, setActiveAccountId] = useState('')
    const [accountsLists, setAccountsLists] = useState<
        {
            id: string
            name: string
            number: string
        }[]
    >([])
    const [balancesIsLoading, setBalancesIsLoading] = useState<boolean>(false)

    const [balancesList, setBalancesList] = useState<
        {
            id: string
            assetName: string
            assetCode: string
            totalBalance: string
            availBalance: string
            earmarkedBalance: string
        }[]
    >([])

    const handleChangeAccountId = useCallback((accountId: string) => {
        if (activeAccountId !== accountId) {
            setActiveAccountId(accountId)
        }
    }, [])

    useEffect(() => {
        async function getEntity() {
            const { success, data } = await getEntityInfo()
            if (!success) {
                return
            }
            onLogin({ entityName: data?.[0]?.entity_id?.name })
            localStorage.setItem('entityName', data?.[0]?.entity_id?.name)
        }
        async function getAccountList() {
            const { success, data } = await getAccounts()
            if (!success) {
                setAccountsLists([])
                return
            }
            setAccountsLists(data)
            setActiveAccountId(data?.[0]?.id)
        }
        getEntity()
        getAccountList()
    }, [])

    useEffect(() => {
        async function getBalanceList() {
            setBalancesIsLoading(true)
            const { success, data } = await getBalances(activeAccountId)
            setBalancesIsLoading(false)
            if (!success) {
                setBalancesList([])
                return
            }
            const formatBalances = data?.map((balance, index) => ({
                id: String(index),
                assetName: balance?.name,
                assetCode: balance?.code,
                totalBalance: Decimal(balance?.amount).toFormat(),
                availBalance: Decimal(balance?.amount_open).toFormat(),
                earmarkedBalance: Decimal(balance?.amount_earmark).neg().toFormat()
            }))
            setBalancesList(formatBalances)
        }
        if (activeAccountId) {
            setBalancesList([])
            getBalanceList()
        }
    }, [activeAccountId])

    return (
        <Box className={styles.balances}>
            <AccountPanel
                accountsLists={accountsLists}
                onChange={handleChangeAccountId}
                activeAccountId={activeAccountId}
            />
            <Balances balances={balancesList} isLoading={balancesIsLoading} />
        </Box>
    )
}

export default memo(AccountBalances)
