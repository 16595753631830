import { requestPost, RpcParams } from '../request'

export async function getEntityInfo() {
    const formatParams: RpcParams = [
        'base.user',
        'search_read_path',
        [[], ['entity_id.name', 'entity_id.number']],
        {},
        {}
    ]
    return requestPost('/json_rpc', formatParams)
}

export async function getAccounts() {
    const formatParams: RpcParams = [
        'tc.account',
        'search_read_path',
        [[], ['number', 'name']],
        {},
        {}
    ]
    return requestPost('/json_rpc', formatParams)
}

export async function getBalances(accountId: string): Promise<{
    success: boolean
    data: {
        name: string
        code: string
        amount: string
        amount_earmark: string
        amount_open: string
        asset_id: string
    }[]
}> {
    const formatParams: RpcParams = ['tc.account', 'get_balances', [accountId], {}, {}]
    return requestPost('/json_rpc', formatParams)
}
